import React, {Fragment, useContext, useEffect, useMemo, useRef, useState} from 'react';
import MessagePreview from '../MessagePreview';
import './Message.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import { Menu } from "primereact/menu";
import { MessageContext } from "../../../context/messageContext";
import Picker from "emoji-picker-react";
import { Dialog } from "primereact/dialog";
import MessageReactions from "../MessageReaction/MessageReactions";
import {Checkbox} from "primereact/checkbox";
import { markAsStar } from '../../../utils/messenger';
import { CompanyContext } from "../../../context/companyContext"
import { DateTime } from 'luxon';
import { convertMimetypeType } from '../../../shared/utility';
import jwtDecode from "jwt-decode";
import { parseJSON } from '../../../utils/utils';


export default function Message(props) {
    const {
        data,
        isMine,
        startsSequence,
        endsSequence,
        showTimestamp,
        animated,
        index,
        handlePlay,
        audioRefs
    } = props;

    const { providerTimezone } = useContext(CompanyContext)
    const [showOptions, setShowOptions] = useState(false);
    const [showOptionsGateway, setOptionsGateway] = useState(false);
    const [showPicker, setShowPicker] = useState(false);
    const [optionItems, setOptionsItems] = useState([]);
    const menu = useRef(null);

    const createdTimestamp = useMemo(() => {
        return DateTime.fromISO(data.created, { zone: 'America/Argentina/Buenos_Aires'}).setZone(providerTimezone)
    }, [data.id]);

    const friendlyTimestamp = createdTimestamp.toFormat('DDDD HH:mm')
    const fulltime =  createdTimestamp.toFormat("HH:mm 'hs' - dd/MM/yy")

    const ackIcon = data.delivered && 'check-double' || data.sent && 'check' || data.read && 'check-double' || 'clock';
    const { selectedForwardMessages, forward, updateMessageContext, updateMessageReaction, updateForward,
        updateSelectedForwardMessages, users } = useContext(MessageContext);
    const [animateMessage, setAnimateMessage] = useState(false);
    const captions = data?.captions !== undefined ? parseJSON(data?.captions) : null;

    const isMobile = () => {
        return window.innerWidth <= 1025;
    };

    useEffect(() => {
        let items = []
        if (['CLOUD-API', 'OPEN-WA'].includes(localStorage.getItem('gatewayWaba'))) {
            items.push(
                {
                    label:
                        <i>
                            <FontAwesomeIcon icon={"fa-regular fa-reply"} style={{ width: '1.4em', height: '1.4em' }} />
                            <span className="menu-font-awesome-icon"> Responder </span>
                        </i>,
                    command: (e) => getMessageData()
                },
                {
                    label:
                        <i>
                            <FontAwesomeIcon icon={"fa-regular fa-share-from-square"} style={{ width: '1.4em', height: '1.4em' }} />
                            <span className="menu-font-awesome-icon"> Reenviar </span>
                        </i>,
                    command: (e) => onReplyHandler()
                },
                {
                    label:
                        <i>
                            <FontAwesomeIcon icon={"fa-regular fa-star"} style={{ width: '1.4em', height: '1.4em' }} />
                            <span className="menu-font-awesome-icon"> {['star'].includes(data?.message_state?.name) ? 'No destacar' : 'Destacar'}</span>
                        </i>,
                    command: (e) => onStarHandler()
                }
            );
            if (['CLOUD-API'].includes(localStorage.getItem('gatewayWaba'))) {
                items.push(
                    {
                        label:
                            <i>
                                <FontAwesomeIcon icon={"fa-regular fa-face-smile"} style={{ width: '1.4em', height: '1.4em' }} />
                                <span className="menu-font-awesome-icon"> Reacción </span>
                            </i>,
                        command: (e) => setShowPicker(!showPicker)
                    }
                );
            }
            setOptionsGateway(true)
        }

        setOptionsItems(items);

    }, [data])

    useEffect(() => {
        if (!animateMessage) {
            setAnimateMessage(animated)
        } else {
            setTimeout(() => {
                setAnimateMessage(animated)
            }, 3000);
        }
    }, [animated])

    const onReplyHandler = () => {
        let _selected_forwared_messages = [...selectedForwardMessages]
        _selected_forwared_messages.push(data.id)
        updateSelectedForwardMessages(_selected_forwared_messages)
        updateForward(true)
    }

    const onStarHandler = () => {
        markAsStar(data.id);
    }

    const getDescription = () => {
        let description = '';
        let user = data.username !== null ? data.username + ' - ' : '';
        let type = (data?.message_type?.description && data?.message_type?.name !== 'message') ? data?.message_type?.description + ' - ' : '';
        if (data?.message_state?.name) type = data?.message_state?.description + ' - ';
        description = data?.message_type?.name === 'transfer' ? user + fulltime : user + type + fulltime
        return description
    }

    const typeIcon = {
        star: <FontAwesomeIcon icon={'fa-regular fa-star'} size="4x" style={{ width: '0.3em', height: '0.3em' }} />,
        delete: <FontAwesomeIcon icon={'fa-regular fa-ban'} size="4x" style={{ width: '0.3em', height: '0.3em' }} />,
        edit: <FontAwesomeIcon icon={'fa-regular fa-pen-to-square'} size="4x" style={{ width: '0.3em', height: '0.3em' }} />,
        ciphertext: <FontAwesomeIcon icon={'fa-regular fa-triangle-exclamation'} size="4x" style={{ width: '0.3em', height: '0.3em' }} />,
        notification: <FontAwesomeIcon icon={'fa-regular fa-paper-plane'} size="4x" style={{ width: '0.3em', height: '0.3em' }} />,
        autoreply: <FontAwesomeIcon icon={'fa-regular fa-paper-plane'} size="4x" style={{ width: '0.3em', height: '0.3em' }} />,
        chatbot: <FontAwesomeIcon icon={'fa-regular fa-robot'} size="4x" style={{ width: '0.3em', height: '0.3em' }} />,
        welcome_message: <FontAwesomeIcon icon={'fa-solid fa-door-open'} size="4x" style={{ width: '0.3em', height: '0.3em' }} />,
        note_reminder: <FontAwesomeIcon icon={'fa-regular fa-calendar'} size="4x" style={{ width: '0.3em', height: '0.3em' }} />,
        scheduled_message: <FontAwesomeIcon icon={'fa-solid fa-calendar-days'} size="4x" style={{ width: '0.3em', height: '0.3em' }} />,
        scheduled_message_sent: <FontAwesomeIcon icon={'fa-solid fa-calendar-days'} size="4x" style={{ width: '0.3em', height: '0.3em' }} />,
        interactive: <FontAwesomeIcon icon={'fa-regular fa-robot'} size="4x" style={{ width: '0.3em', height: '0.3em' }} />,
        user: <FontAwesomeIcon icon={'fa-regular fa-user'} size="4x" style={{ width: '0.3em', height: '0.3em' }} />,
    };

    const getTypeIcon = () => {
        let type = data.username !== null ? 'user' : data?.message_type?.name !== null ? data?.message_type?.name : '';
        if (type !== 'user' && data?.message_state?.name) type = data?.message_state?.name;
        return typeIcon[type];
    };

    const icon = (
        <small className='message-icon'>
            {getTypeIcon()}
            &nbsp;&nbsp;
        </small>
    )

    const description = getDescription();
    const note = (
        <div className='center mt-2 message-note'>
            <div className="bubble-container">
                <div className="bubble">
                    <div className="p-button-raised">

                        <FontAwesomeIcon icon={"fa-regular fa-comment-dots"} color={'grey'}
                            size={"4x"}
                            style={{ width: '0.4em', height: '0.4em' }} />
                        &nbsp;&nbsp;
                        {data.emoji}

                  </div>
                  <p className="" style={{color: "#4a4141"}}>
                      <small>{description}</small>
                  </p>
              </div>
          </div>
      </div>
    );

    const transfer = ((message, transferredToMe, isAssigned) => {
        const icon_settings = {
            true: {
                icon: "fa-regular fa-share",
                color: "#3975ad",
                style: { width: '0.4em', height: '0.4em', transform: transferredToMe ? '' : 'scaleX(-1)'}
            },
            false: {
                icon: "fa-regular fa-user-large-slash",
                color: "#c22525",
                style: { width: '0.4em', height: '0.4em'}
            }
        };
        const { icon, color, style } = icon_settings[isAssigned];
        return (
            <div className={`center mt-2 message-transfer`}>
                <div className="bubble-container">
                    <div className="bubble">
                        <div className="p-button-raised" style={{marginRight: '10px'}}>
                            <FontAwesomeIcon 
                            icon={icon}
                            color={color}
                            size={"4x"}
                            style={style} />
                            &nbsp;&nbsp;
                            {message}
                        </div>
                        <p className="" style={{ color: "#4a4141" }}>
                            <small>{description}</small>
                        </p>
                    </div>
                </div>
            </div>
        )
    });

    const transfer_message = () => {
        const token = localStorage.getItem('token');
        const parsedData = JSON.parse(data.emoji);
        const userId = jwtDecode(token).user_claims.user_id;
        const transferredToMe = parsedData.transferred_to === userId;
        const transferredToUser = users.find(user => user.id === parsedData.transferred_to);
        const transferredToGroupName = parsedData.transferred_to_group;
        const isAssigned = !!transferredToUser?.username;
        let message;
        
        if (transferredToGroupName) {
            message = `Esta conversación fue derivada al grupo ${transferredToGroupName} (Operador ${transferredToUser?.username ? transferredToUser?.username : 'no disponible'})`;
        } 
        else if (transferredToMe) {
            message = 'Te han derivado esta conversación';
        } 
        else {
            message = transferredToUser?.username 
                ? `Esta conversación fue derivada a ${transferredToUser.username}`
                : 'Conversación desasignada';
        }
    
        return transfer(message, transferredToMe, isAssigned);
    };

    const note_reminder = () => {
        const dateNow = DateTime.now().setZone(providerTimezone).toFormat('dd/MM/yyyy');

        let noteReminderDate = DateTime.fromISO(data.note_reminder.reminder_date).setZone(providerTimezone);
        if (noteReminderDate.invalid){
            noteReminderDate = DateTime.fromFormat(data.note_reminder.reminder_date, "yyyy-MM-dd HH:mm:ss" );
        }

        let activeLabel = DateTime.fromFormat(noteReminderDate.toFormat('dd/MM/yyyy'), 'dd/MM/yyyy') >= DateTime.fromFormat(dateNow, 'dd/MM/yyyy') && !data.note_reminder.seen ? 'activo' : 'inactivo';
        let color = DateTime.fromFormat(noteReminderDate.toFormat('dd/MM/yyyy'), 'dd/MM/yyyy') >= DateTime.fromFormat(dateNow, 'dd/MM/yyyy') && !data.note_reminder.seen ? 'orange' : 'grey';

        let tooltipLabel = (
            <Fragment>
                <p>Recordatorio {activeLabel} : {noteReminderDate.toFormat('dd-MM-yyyy HH:mm')}</p>
            </Fragment>
        )

        let tooltipId = 'note-reminder-bell' + data.id
        return (
            <div className='center mt-2 note-reminder'>
                <div className="bubble-container">
                    <div className="bubble">
                        <div className="p-button-raised">
                            <FontAwesomeIcon icon={"fa-regular fa-bell"} color={color} data-tip data-for={tooltipId}
                                             size={"4x"}
                                             style={{width: '0.4em', height: '0.4em'}}/>
                            &nbsp;&nbsp;
                            {data.emoji}
                        </div>
                        <p className="" style={{color: "#4a4141"}}>
                            <small>{description}</small>
                        </p>
                    </div>
                </div>
                {!isMobile() &&
                    <ReactTooltip id={tooltipId} place="top" effect="solid" key={color}>
                        {tooltipLabel}
                    </ReactTooltip>
                }
            </div>
        )
    };

    const getIcon = (mimetype) => {
        let type_icon = convertMimetypeType(mimetype)
        if (type_icon === 'document'){
            type_icon = 'file-pdf'
        }
        return type_icon
    }

    const scheduled_message = () => {
        const dateNow = DateTime.now().setZone(providerTimezone).toFormat('dd/MM/yyyy');
        let noteReminderDate = DateTime.fromISO(data.note_reminder.reminder_date).setZone(providerTimezone);
        if (noteReminderDate.invalid){
            noteReminderDate = DateTime.fromFormat(data.note_reminder.reminder_date, "yyyy-MM-dd HH:mm:ss" );
        }

        let activeLabel = DateTime.fromFormat(noteReminderDate.toFormat('dd/MM/yyyy'), 'dd/MM/yyyy') >= DateTime.fromFormat(dateNow, 'dd/MM/yyyy') && !data.note_reminder.seen ? 'activo' : 'inactivo';
        let color = DateTime.fromFormat(noteReminderDate.toFormat('dd/MM/yyyy'), 'dd/MM/yyyy') >= DateTime.fromFormat(dateNow, 'dd/MM/yyyy') && !data.note_reminder.seen ? 'orange' : 'grey';

        let tooltipLabel = (
            <p>Mensaje programado {activeLabel} : {noteReminderDate.toFormat('dd-MM-yyyy HH:mm')}</p>
        )

        const _animateMessage = animateMessage ? (isMine ? 'bubble-container-animation-out' : 'bubble-container-animation-in') : '';

        let tooltipId = 'note-reminder-bell' + data.id
        return (
            <div className='center mt-2 note-reminder'>
                <div className="bubble-container">

                    <div className={['bubble', `${_animateMessage}`].filter(x => x !== '').join(' ')}>
                        <div className="p-button-raised">
                            <FontAwesomeIcon icon={"fa-regular fa-robot"} color={color} data-tip data-for={tooltipId}
                                             size={"4x"}
                                             style={{width: '0.4em', height: '0.4em'}}/>
                            &nbsp;&nbsp;
                            {data.mimetype ? <span> {captions.caption} &nbsp;&nbsp;<FontAwesomeIcon icon={ 'fa-regular fa-' + getIcon(data.mimetype)} size={"4x"} style={{width: '0.4em', height: '0.4em'}}/> </span>: data.emoji}
                        </div>
                        <p className="" style={{color: "#4a4141"}}>
                            <small>{description}</small>
                        </p>
                    </div>
                </div>
                {!isMobile() &&
                    <ReactTooltip id={tooltipId} place="top" effect="solid" key={color}>
                        {tooltipLabel}
                    </ReactTooltip>
                }
            </div>
        )
    };

    const ack = (
        isMine && (
            <small>
                &nbsp;&nbsp;
                <FontAwesomeIcon icon={data.read ? 'check-double' : ackIcon} spin={ackIcon === "clock"}
                    color={data.read ? '#028dc9' : '#4a5960'}
                    size={"4x"}
                    style={{ width: '0.4em', height: '0.4em' }} />
            </small>
        )
    )

    const getMessageData = () => {
        let message_context = {
            'id': data.id,
            'body': data.emoji,
            'mime_type': data.mimetype,
            'message_type': data.message_type ? data.message_type : null,
        }
        updateMessageContext(message_context)
    }

    const toggleMenu = (e) => {
        menu.current.toggle(e);
    }

    const showIconOptions = showOptions && showOptionsGateway && !forward && data?.message_state?.name !== 'delete' ? (
        <Fragment>
            <Menu
                model={optionItems} popup ref={menu}
                onHide={(e) => setShowOptions(false)}
            />
            <button className="p-link" onClick={(e) => toggleMenu(e)}>
                <FontAwesomeIcon icon={"chevron-down"} style={{ position: 'relative', bottom: '12px' }} color={'#515151'} size={"sm"} />
            </button>
        </Fragment>
    ) : null;

    const onEmojiClick = (event, emojiObject) => {
        event.preventDefault();
        updateMessageReaction({
            message_id: data.id,
            emoji: emojiObject.emoji
        })
        setShowPicker(false);
    };

    const getEmojisForm = () => {
        return (
            <Dialog header="Emojis" visible={showPicker} style={{ width: '40vw' }} onHide={() => setShowPicker(false)}>
                <div>
                    {showPicker && (<Picker
                        disableSearchBar={true}
                        pickerStyle={{ width: "100%", zIndex: "0" }}
                        onEmojiClick={onEmojiClick} />)}
                </div>
            </Dialog>
        )
    }

    const messageReactions = data.reactions.length > 0 ? (
        <Fragment>
            <MessageReactions data={data.reactions} body={data.emoji} id={data.id} contact={data.author_name} />
        </Fragment>
    ) : null;

    const checkboxForwards = forward ? (
        <div className={'forward-message'}>
            <Checkbox name={'forward'} value={data.id} onChange={props.onMessageForwardChange}
                checked={selectedForwardMessages.some((item) => item === data.id)}
            />
        </div>
    ) : null;

    const _animateMessage = animateMessage ? (isMine ? 'bubble-container-animation-out' : 'bubble-container-animation-in') : '';

    const message = (
        <div className={[
            'flex message',
            `${isMine ? 'mine' : data?.message_type?.name === 'ciphertext' ? 'retry' : 'other'}`,
            `${data.message_type?.name}`,
            `${data.message_state?.name}`,
            `${startsSequence ? 'start' : ''}`,
            `${endsSequence ? 'end' : ''}`,
            `${data.failed ? 'failed' : ''}`,
        ].filter(x => x !== '').join(' ')}>
            {
                showTimestamp &&
                <div className='center mt-2 date'>
                    <div className="date-container">
                        <div className="timestamp">
                            {friendlyTimestamp}
                        </div>
                    </div>
                </div>
            }
            {getEmojisForm()}

            <div className={[
                'bubble-container',
                `${selectedForwardMessages.filter(item => item === data.id)[0] !== undefined ? 'message-selection-highlight' : ''}`
            ].filter(x => x !== '').join(' ')}
                onMouseEnter={() => setShowOptions(true)}
                onMouseLeave={() => setShowOptions(false)}>
                {checkboxForwards}
                <div className={['bubble',
                    `${isMine ? 'ml-auto' : ''}`,
                    `${_animateMessage}`].filter(x => x !== '').join(' ')}
                    title={friendlyTimestamp} >
                    <div className={''}>
                        <MessagePreview index={index} data={data} options={showIconOptions} isMine={isMine} handlePlay={handlePlay} audioRefs={audioRefs} />
                    </div>
                    <p className={`${isMine ? 'flex justify-content-end flex-wrap"' : 'flex justify-content-start flex-wrap"'}`} style={{ color: "#4a4141" }}>
                        {icon}
                        <small>{description}</small>
                        {ack}
                    </p>
                </div>
                {messageReactions}
            </div>
        </div>
    );

    const renderMessage = (message_type) => {
        let form = null;
        if (message_type === 'note') {
            form = note;
        } else if (message_type === 'note_reminder') {
            form = note_reminder();
        }
        else if (message_type === 'scheduled_message') {
            form = scheduled_message();
        } else if (message_type === 'transfer') {
            form = transfer_message();
        } else {
            form = message;
        }
        return form
    }

    return (
        renderMessage(data.message_type?.name)
    );
}