import React, {Component, Fragment} from "react";
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Link, withRouter } from "react-router-dom";
import { Menu } from "primereact/menu";
import { TableContext } from "../../../context/tableContext";
import GeneralAlert from "../../General/GeneralAlert";
import {showErrorMessage, showSuccessMessage, showWarningMessage} from "../../../shared/messagesToast";
import SweetAlert from "react-bootstrap-sweetalert";
import ApiLoan from '../../../service/ApiLoanService';

class ToolbarGeneric extends Component {
	static contextType = TableContext;
	constructor() {
        super();
        this.state = {
			alert: null,
			windowRefreshPage: null,
		}
		this.apiService = new ApiLoan();
	}
	cleanFilters = () => {
		let params = {};
		params["reset"] = true;
		this.context.updateTableFields(params);
	};

	onRefreshPage = (event, url) => {
		event.preventDefault();
		let detailError = "Error en Refrescar Página";
		let detailSuccess = "Se refresco correctamente";
		let title = "Refrescar Página"
		this.apiService.getResource({
			url: url,
		}).then(response => {
			this.setState({
				alert: null
			});
			if (response.data?.message !== undefined) {
				detailSuccess = response.data?.message
			}
			if (response.status === 200){
				showSuccessMessage(this, title, detailSuccess);
			} else {
				showErrorMessage(this, title, detailSuccess);
			}
		}).catch((error) => {
			this.setState({
				alert: null
			});
			if (error.response.data?.message !== undefined) {
				detailError = error.response.data?.message
			}
			showErrorMessage(this, title, detailError);
		}).finally(() => this.setState({windowCreateAnalytic: null}));
	}

	confirmRefreshPage = (event,url) => {
		this.setState({
			windowRefreshPage: (
				<Fragment>
					<SweetAlert
						info
						showCancel
						cancelBtnText={<i class="pi pi-times">&nbsp;No</i>}
						confirmBtnText={<i class="pi pi-check">&nbsp;Si</i>}
						title="¡Está seguro!"
						onConfirm={() => this.onRefreshPage(event,url)}
						onCancel={() => this.setState({ windowRefreshPage: null })}
						confirmBtnCssClass="p-button p-component p-button-success p-button-rounded"
						cancelBtnCssClass="p-button p-component p-button-danger p-button-rounded"
					>
						¿Que quieres refrescar la página de todos los clientes?
					</SweetAlert>
				</Fragment>
			),
		});
	};

	render() {
		let canCreate = this.props.action.add !== undefined;
		let new_button = null;
		if (canCreate) {
			if (this.props.customTableHeaderButtons) {
				new_button = (
					<Fragment>
						<Menu
							model={this.props.customTableHeaderButtons}
							ref={el => (this.menu = el)}
							popup={true}
						/>
						<Button
							label="Nuevo"
							icon="pi pi-plus"
							onClick={event => this.menu.toggle(event)}
						/>
					</Fragment>
				);
			} else {
				let action_show = this.props.action.show;

				if (action_show !== undefined) {
					if (action_show[action_show.length - 1] === '/'){
						action_show = action_show.slice(0, -1);
					}
				}

				let uri = this.props.match.url === '/' ? action_show : this.props.match.url;
				new_button = (
					<Fragment>
						<Link to={uri + this.props.action.add}>
							<Button
								icon="pi pi-plus"
								label="Nuevo"
							/>
						</Link>
					</Fragment>
				);
			}
		}

		let filter_button = (
			<Fragment>
				<Button
					label="Recargar"
					icon="pi pi-refresh"
					onClick={this.cleanFilters}
				/>
			</Fragment>
		);

		let export_button;

		if (this.props.action.addExportButton){
			export_button = (
				<Fragment>
					<Button
						label="Exportar"
						icon="pi pi-download"
						className="p-button-info ml-2"
						onClick={(e) => this.props.action.addExportButton.eventButton()}
					/>
				</Fragment>
			);
		}

		let refresh_page;

		if (this.props.action.refresh_page) {
			refresh_page = (
				<Fragment>
					<Button
						onClick={(event) => this.confirmRefreshPage(event, this.props.action.refresh_page)}
						icon="pi pi-refresh"
						label="Refrescar Página"
					/>
				</Fragment>
			)
		}



		let add_generic_button;
		if (this.props.action.addGenericButton){
			let generic_button_icon = this.props.action.addGenericButton.icon === undefined ?
				'pi pi-plus' : this.props.action.addGenericButton.icon;
			add_generic_button = (
				<Button
					label={this.props.action.addGenericButton.label}
					icon={generic_button_icon}
					disabled={this.props.action.addGenericButton.button}
					className={"ml-2"}
					onClick={(e) => this.props.action.addGenericButton.eventButton()}
				/>
			);
			if (this.props.action.addGenericButton.alert){
				add_generic_button = (
					<GeneralAlert
						{...this.props.action.addGenericButton.alert}
						onConfirm={this.props.action.addGenericButton.eventButton}
					>
						{(showAlert) => {
							return (
								<Button
									label={this.props.action.addGenericButton.label}
									icon={generic_button_icon}
									disabled={this.props.action.addGenericButton.button}
									className={"ml-2"}
									onClick={(e) => showAlert()}
								/>
							);
						}}
					</GeneralAlert>
				)
			}
		}

		const leftContents = (
			<Fragment>
				{filter_button}
				{export_button}
			</Fragment>
		);

		const rightContents = (
			<Fragment>
				<div style={{ marginRight: '8px' }}>{refresh_page}</div>
				{new_button}
				{add_generic_button}
			</Fragment>
		);

		return (
			<Fragment>
				{this.state.windowRefreshPage}
				<Toolbar left={leftContents} right={rightContents} style={{background: 'none'}} />
			</Fragment>
		);
	}
}

export default withRouter(ToolbarGeneric);
