import React, {useContext, useEffect, useRef, useState} from 'react';
import ApiLoan from "../../../service/ApiLoanService";
import { Dropdown } from 'primereact/dropdown';
import { useHistory } from "react-router-dom";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import './ReassignMessages.css';
import { MessageContext } from '../../../context/messageContext';
import { MultiSelect } from 'primereact/multiselect';
import { switchRole } from '../../../shared/roles';

const ReassignMessages = (props) => {
    const message_context = useContext(MessageContext);
    const [usersFrom, setUsersFrom] = useState(null);
    const [usersTo, setUsersTo] = useState(null);
    const [user, setUser] = useState({
        assign_group_from: null,
        assign_group_to: null,
        assign_custom_tags_from: null,
        assign_user_from: null,
        assign_user_to: null
    });
    const [groups, setGroups] = useState({
        options: [],
        page: 1,
        num_results: 0,
        loading: true
    });
    const [customTags, setCustomTags] = useState({
        loading: true,
        values: []
    })
    const [errors, setErrors] = useState({});
    const history = useHistory();
    const toast = useRef(null);
    const [hasAudience, setHasAudience] = useState(false);
    const [transferLoading, setTransferLoading] = useState(false);

    const isMobile = () => {
        return window.innerWidth <= 750;
    };

    useEffect(() => {
        const apiService = new ApiLoan();

        apiService.getResources({
            url: '/conversations/users',
        }).then(response => {
            let objects = response.data.objects;

            let data = objects.map(obj => {
                return {
                    'id': obj.id,
                    'username': obj.username
                }
            })

            setUsersFrom(data)
            let users_to = [...data];

            users_to.push({
                'id': 'unassigned',
                'username': 'Sin asignar'
            });

            setUsersTo(users_to);

            if(['audience'].includes(switchRole())){
                let isUser = objects.find((element) => element.username === localStorage.getItem('username'));
                if (isUser) {
                    onChangeHandler(isUser.id, 'assign_user_from')
                    setHasAudience(true);
                }
            }

        }).catch(error => {
            history.push( '/audiences' );
        })
    }, [history]);

    useEffect(() => {
        let mounted = true;
        const apiService = new ApiLoan();
        if (mounted) {
            apiService.getResources({
                url: '/groups',
                page: groups.page
            }).then(response => {
                const objects = response.data.objects;
                const group_objects = objects.map(object => {
                    return {
                        'id': object.id,
                        'name': object.name,
                    }
                });
                setGroups((prevState) => {
                    return {
                        ...prevState,
                        options: [
                            ...prevState.options,
                            ...group_objects
                        ],
                        page: response.data.page,
                        num_results: response.data.num_results,
                        loading: response.data.page < response.data.total_pages
                    }
                });
            });
        }
        return () => mounted = false;
    }, [groups.page]);

    useEffect(() => {
        const apiService = new ApiLoan();

        apiService.getResources({
            url: '/all_custom_tags',
        }).then(response => {
            const objects = response.data.objects;
            const tags = objects.map(object => {
                return {
                    'id': object.id,
                    'name': object.name
                }
            });

            tags.sort(function (a, b) {
                return a.name.localeCompare(b.name);
            });
            setCustomTags(prevState => {
                return {
                    ...prevState,
                    loading: false,
                    values: tags
                }
            });
        });
        return () => {
            setCustomTags({
                loading: true,
                values: []
            });
        }
    }, []);

    useEffect(() => {
        props.onChange(user);
        setErrors({});
    }, [user])

    useEffect(() => {
        if (!user.assign_user_to || user.assign_user_to.id === "unassigned") onChangeHandler(null, 'assign_group_to')
    }, [user.assign_user_to])

    useEffect(() => {
        if (!user.assign_user_from) onChangeHandler(null, 'assign_group_from')
    }, [user.assign_user_from])

    const onChangeHandler = (value, field) => {
        setUser((prevState) => {
            return {
                ...prevState,
                [field]: value
            }
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setTransferLoading(true);
        let detailSuccess = 'Se guardo satisfactoriamente';
        const apiService = new ApiLoan();
        apiService.postResource({
            'url': '/conversations/reassign',
            'data': user
        }).then(response => {
            if (response.data?.error !== undefined) {
                toast.current.show({
                    severity: 'warn',
                    summary: 'Transferir Chats',
                    detail: 'Ya existe una transferencia en curso. Por favor, espere a que termine.'
                });
                setTransferLoading(false);
                return;
            }
            if (response.data?.message !== undefined) {
                detailSuccess = response.data?.message
            }
            toast.current.show({
                severity: 'info',
                summary: 'Transferir Chats',
                detail: 'Transferiendo chats. Esto puede llevar un momento.'
            });
            //setTimeout(() => message_context.updateDialogReassignMessages(false), 2000);
        }).catch(error => {
            let detail = 'Verifique el formulario'
            let status = 'error'
            setErrors(error?.response?.data || {});
            toast.current.show({ severity: status, summary: 'Transferir Chats', detail: detail });
        });
    }

    const getFieldError = (errors, field) => {
        let form = null;
        if (errors[field] !== null) {
            form = <p style={{ color: '#f44336' }} className="p-error"> <small>{errors[field]}</small> </p>
        }
        return form;
    }

    const multiselectCustomTagsTemplate = value => {
        if (value) {
            return (
                <div className="my-multiselected-item-token">
                    <span>{value.name}</span>
                </div>
            );
        } else {
            return <span className="my-multiselected-empty-token">Etiquetas</span>;
        }
    };

    return (
        <div>
            <Toast ref={toast} />
            <p className='reassign-title'><b>Los chats de</b></p>
            <div className="formgrid grid">
                <div className="flex p-fluid col-4 md:col-4">
                    <div className="field col-12">
                        <span className="p-float-label">
                            <Dropdown placeholder="Usuario" value={user.assign_user_from} options={usersFrom}
                                      className={errors?.assign_user_from && "p-invalid"} onChange={(e) => onChangeHandler(e.value, 'assign_user_from')} optionLabel="username"
                                      optionValue="id" filter={!isMobile()} disabled={hasAudience} showClear={!hasAudience} filterBy="username" />
                            <label htmlFor="dropdown">Usuario</label>
                        </span>
                        {getFieldError(errors, 'assign_user_from')}
                    </div>
                    <div className="field col-12">
                            <span className="p-float-label">
                                <Dropdown placeholder="Equipo" disabled={!user.assign_user_from} value={user.assign_group_from} options={groups?.options}
                                          onChange={(e) => onChangeHandler(e.value, 'assign_group_from')} optionLabel="name"
                                          optionValue="id" filter={!isMobile()} showClear filterBy="name" />
                                <label htmlFor="dropdown">Equipo</label>
                            </span>
                            {getFieldError(errors, 'assign_group_from')}
                    </div>
                    <div className="field col-12">
                    <span className="p-float-label">
                            <MultiSelect selectionLimit={1} id="assign_custom_tags_from" className={errors?.assign_custom_tags_from && "p-invalid"}
                                value={user.assign_custom_tags_from} optionLabel="name" options={customTags.values}
                                onChange={(e) => onChangeHandler(e.value, 'assign_custom_tags_from')}
                                placeholder="Etiquetar"
                                selectedItemTemplate={multiselectCustomTagsTemplate}
                                selectedItemsLabel={'{0} etiquetas'}
                                maxSelectedLabels={0}
                            />
                            <label htmlFor="assign_custom_tags_from">Etiquetar</label>
                        </span>
                            {getFieldError(errors, 'assign_custom_tags_from')}
                    </div>
                </div>
            </div>
            <p className='reassign-title'><b>Transferir a</b></p>
            <div className="formgrid grid">
                <div className="flex p-fluid col-6 md:col-6">
                    <div className="field col-10">
                            <span className="p-float-label">
                                <Dropdown placeholder="Usuario" value={user.assign_user_to} options={usersTo}
                                          className={errors?.assign_user_to && "p-invalid"} onChange={(e) => onChangeHandler(e.value, 'assign_user_to')} optionLabel="username"
                                          optionValue="id" filter={!isMobile()} showClear filterBy="username" />
                                <label htmlFor="dropdown">Usuario</label>
                            </span>
                            {getFieldError(errors, 'assign_user_to')}
                    </div>
                    <div className="field col-12">
                            <span className="p-float-label">
                                <Dropdown placeholder="Equipo" disabled={!user.assign_user_to || user.assign_user_to.id === "unassigned"} value={user.assign_group_to} options={groups?.options}
                                          onChange={(e) => onChangeHandler(e.value, 'assign_group_to')} optionLabel="name"
                                          optionValue="id" filter={!isMobile()} showClear filterBy="name" />
                                <label htmlFor="dropdown">Equipo</label>
                            </span>
                            {getFieldError(errors, 'assign_group_to')}
                    </div>
                </div>
            </div>
            <div className="formgrid grid">
                <div className="field col-12">
                    <Button label={transferLoading ? 'Transfiriendo...' : 'Transferir'}
                            icon={transferLoading ? 'pi pi-spin pi-spinner' : 'pi pi-plus'}
                            className="ml-auto reassign-button"
                            onClick={(e) => handleSubmit(e)}
                    />
                </div>
            </div>
        </div>
    );

}
export default ReassignMessages;